import React from 'react';
import moment from '../lib/moment';

export function renderMonthElement({ month, onMonthSelect, onYearSelect }) {
  // prettier-ignore
  const years = Array(10).fill().map((_, index) => month.year() - 5 + index);

  return (
    <>
      <div className="CalendarMonth_title">{month.format('MMMM YYYY')}</div>
      <div className="CalendarMonth_selects">
        <select className="CalendarMonth_select" value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <select className="CalendarMonth_select" value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
