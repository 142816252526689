import React, { useState } from 'react';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import { renderMonthElement } from './react_dates';
import moment from '../lib/moment';

function DateCalendar({ initialDate, minDate, maxDate, onChange }) {
  const [date, setDate] = useState(initialDate ? moment(initialDate) : null);

  const handleDateChange = (date) => {
    setDate(date);
    onChange(date);
  };

  const isOutsideRange = (day) => {
    return (minDate && moment(day).isBefore(minDate)) || (maxDate && moment(day).isAfter(maxDate));
  };

  return (
    <DayPickerSingleDateController
      date={date}
      onDateChange={handleDateChange}
      focused
      initialVisibleMonth={() => date || moment()}
      transitionDuration={0}
      noBorder
      hideKeyboardShortcutsPanel
      isOutsideRange={isOutsideRange}
      renderMonthElement={renderMonthElement}
      daySize={40}
      horizontalMonthPadding={12}
    />
  );
}

export default DateCalendar;
