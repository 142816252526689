import c0 from './admin_roles_controller';
import c1 from './ajax_facebox_trigger_controller';
import c2 from './ajax_filter_controller';
import c3 from './ajax_popover_trigger_controller';
import c4 from './alert_box_controller';
import c5 from './alert_controller';
import c6 from './alternative_submit_controller';
import c7 from './attach_loader_controller';
import c8 from './base_chart_controller';
import c9 from './bulk_delivery_form_controller';
import c10 from './channel_settlement_form_controller';
import c11 from './channel_settlement_form_difference_controller';
import c12 from './channels/form_controller';
import c13 from './checklist_controller';
import c14 from './contractor_portal/timeline_chart_controller';
import c15 from './copy_to_clipboard_controller';
import c16 from './costs/costs/bulk_merges/new_controller';
import c17 from './costs/costs/invoicing_configuration/account_overrides_controller';
import c18 from './costs/costs/invoicing_configuration/form_controller';
import c19 from './costs/costs/invoicing_configuration/invoice_rule_overrides_controller';
import c20 from './costs/costs/pricing_configuration/form_controller';
import c21 from './costs/costs/validity_configuration/form_controller';
import c22 from './customer_autocomplete_controller';
import c23 from './dashboard_reservation_popover_controller';
import c24 from './devise/password_hide_show_controller';
import c25 from './devise/password_strength_controller';
import c26 from './dirty_form_controller';
import c27 from './dismiss_error_controller';
import c28 from './email_signature_controller';
import c29 from './executor_portal/todos/new_controller';
import c30 from './facebox_ajax_form_controller';
import c31 from './facebox_trigger_controller';
import c32 from './financial_period_select_controller';
import c33 from './imports/attribute_configurations_controller';
import c34 from './imports/wizard_controller';
import c35 from './inline_datepicker_controller';
import c36 from './inline_edit_controller';
import c37 from './legacy_customer_autocomplete_controller';
import c38 from './legacy_location_field_controller';
import c39 from './legacy_select_bulk_controller';
import c40 from './liquid_tags_controller';
import c41 from './liquid_text_area_controller';
import c42 from './location_lookup_controller';
import c43 from './mail_template_variables_controller';
import c44 from './mentionable_controller';
import c45 from './menu_controller';
import c46 from './message_delivery_form_controller';
import c47 from './meter_reading_controller';
import c48 from './meter_reading_set_controller';
import c49 from './organization_roles_controller';
import c50 from './packages/package_entries_controller';
import c51 from './park_map_location_controller';
import c52 from './pass_on_costs/nested_form_controller';
import c53 from './payment_schedule_controller';
import c54 from './payment_schedule_rule_controller';
import c55 from './payments/form_controller';
import c56 from './policies/consent_settings_controller';
import c57 from './policies/consent_table_controller';
import c58 from './policies/data_processor_controller';
import c59 from './postalcode_autocomplete_controller';
import c60 from './price_input_controller';
import c61 from './refreshing_form_controller';
import c62 from './register_count_controller';
import c63 from './register_count_difference_controller';
import c64 from './remove_element_controller';
import c65 from './reorderable_item_controller';
import c66 from './reservation_extras_form_controller';
import c67 from './reservation_form_controller';
import c68 from './reservation_guest_group_form_controller';
import c69 from './row_selection_controller';
import c70 from './select2_ajax_search_controller';
import c71 from './select2_controller';
import c72 from './select2_facetted_controller';
import c73 from './sources_selection_controller';
import c74 from './stats/copy_to_clipboard_controller';
import c75 from './stats/period_selector_controller';
import c76 from './stats/widget_controller';
import c77 from './stats_chart_controller';
import c78 from './stats_heatmap_controller';
import c79 from './stats_table_chart_controller';
import c80 from './student_certificate_controller';
import c81 from './todos_planning_list_controller';
import c82 from './turbo_loader_controller';
import c83 from './user_task_controller';
import c84 from './user_tasks_controller';
export const definitions = [
	{identifier: 'admin-roles', controllerConstructor: c0},
	{identifier: 'ajax-facebox-trigger', controllerConstructor: c1},
	{identifier: 'ajax-filter', controllerConstructor: c2},
	{identifier: 'ajax-popover-trigger', controllerConstructor: c3},
	{identifier: 'alert-box', controllerConstructor: c4},
	{identifier: 'alert', controllerConstructor: c5},
	{identifier: 'alternative-submit', controllerConstructor: c6},
	{identifier: 'attach-loader', controllerConstructor: c7},
	{identifier: 'base-chart', controllerConstructor: c8},
	{identifier: 'bulk-delivery-form', controllerConstructor: c9},
	{identifier: 'channel-settlement-form', controllerConstructor: c10},
	{identifier: 'channel-settlement-form-difference', controllerConstructor: c11},
	{identifier: 'channels--form', controllerConstructor: c12},
	{identifier: 'checklist', controllerConstructor: c13},
	{identifier: 'contractor-portal--timeline-chart', controllerConstructor: c14},
	{identifier: 'copy-to-clipboard', controllerConstructor: c15},
	{identifier: 'costs--costs--bulk-merges--new', controllerConstructor: c16},
	{identifier: 'costs--costs--invoicing-configuration--account-overrides', controllerConstructor: c17},
	{identifier: 'costs--costs--invoicing-configuration--form', controllerConstructor: c18},
	{identifier: 'costs--costs--invoicing-configuration--invoice-rule-overrides', controllerConstructor: c19},
	{identifier: 'costs--costs--pricing-configuration--form', controllerConstructor: c20},
	{identifier: 'costs--costs--validity-configuration--form', controllerConstructor: c21},
	{identifier: 'customer-autocomplete', controllerConstructor: c22},
	{identifier: 'dashboard-reservation-popover', controllerConstructor: c23},
	{identifier: 'devise--password-hide-show', controllerConstructor: c24},
	{identifier: 'devise--password-strength', controllerConstructor: c25},
	{identifier: 'dirty-form', controllerConstructor: c26},
	{identifier: 'dismiss-error', controllerConstructor: c27},
	{identifier: 'email-signature', controllerConstructor: c28},
	{identifier: 'executor-portal--todos--new', controllerConstructor: c29},
	{identifier: 'facebox-ajax-form', controllerConstructor: c30},
	{identifier: 'facebox-trigger', controllerConstructor: c31},
	{identifier: 'financial-period-select', controllerConstructor: c32},
	{identifier: 'imports--attribute-configurations', controllerConstructor: c33},
	{identifier: 'imports--wizard', controllerConstructor: c34},
	{identifier: 'inline-datepicker', controllerConstructor: c35},
	{identifier: 'inline-edit', controllerConstructor: c36},
	{identifier: 'legacy-customer-autocomplete', controllerConstructor: c37},
	{identifier: 'legacy-location-field', controllerConstructor: c38},
	{identifier: 'legacy-select-bulk', controllerConstructor: c39},
	{identifier: 'liquid-tags', controllerConstructor: c40},
	{identifier: 'liquid-text-area', controllerConstructor: c41},
	{identifier: 'location-lookup', controllerConstructor: c42},
	{identifier: 'mail-template-variables', controllerConstructor: c43},
	{identifier: 'mentionable', controllerConstructor: c44},
	{identifier: 'menu', controllerConstructor: c45},
	{identifier: 'message-delivery-form', controllerConstructor: c46},
	{identifier: 'meter-reading', controllerConstructor: c47},
	{identifier: 'meter-reading-set', controllerConstructor: c48},
	{identifier: 'organization-roles', controllerConstructor: c49},
	{identifier: 'packages--package-entries', controllerConstructor: c50},
	{identifier: 'park-map-location', controllerConstructor: c51},
	{identifier: 'pass-on-costs--nested-form', controllerConstructor: c52},
	{identifier: 'payment-schedule', controllerConstructor: c53},
	{identifier: 'payment-schedule-rule', controllerConstructor: c54},
	{identifier: 'payments--form', controllerConstructor: c55},
	{identifier: 'policies--consent-settings', controllerConstructor: c56},
	{identifier: 'policies--consent-table', controllerConstructor: c57},
	{identifier: 'policies--data-processor', controllerConstructor: c58},
	{identifier: 'postalcode-autocomplete', controllerConstructor: c59},
	{identifier: 'price-input', controllerConstructor: c60},
	{identifier: 'refreshing-form', controllerConstructor: c61},
	{identifier: 'register-count', controllerConstructor: c62},
	{identifier: 'register-count-difference', controllerConstructor: c63},
	{identifier: 'remove-element', controllerConstructor: c64},
	{identifier: 'reorderable-item', controllerConstructor: c65},
	{identifier: 'reservation-extras-form', controllerConstructor: c66},
	{identifier: 'reservation-form', controllerConstructor: c67},
	{identifier: 'reservation-guest-group-form', controllerConstructor: c68},
	{identifier: 'row-selection', controllerConstructor: c69},
	{identifier: 'select2-ajax-search', controllerConstructor: c70},
	{identifier: 'select2', controllerConstructor: c71},
	{identifier: 'select2-facetted', controllerConstructor: c72},
	{identifier: 'sources-selection', controllerConstructor: c73},
	{identifier: 'stats--copy-to-clipboard', controllerConstructor: c74},
	{identifier: 'stats--period-selector', controllerConstructor: c75},
	{identifier: 'stats--widget', controllerConstructor: c76},
	{identifier: 'stats-chart', controllerConstructor: c77},
	{identifier: 'stats-heatmap', controllerConstructor: c78},
	{identifier: 'stats-table-chart', controllerConstructor: c79},
	{identifier: 'student-certificate', controllerConstructor: c80},
	{identifier: 'todos-planning-list', controllerConstructor: c81},
	{identifier: 'turbo-loader', controllerConstructor: c82},
	{identifier: 'user-task', controllerConstructor: c83},
	{identifier: 'user-tasks', controllerConstructor: c84},
];
