import React, { useState } from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { PureSingleDatePicker } from 'react-dates/lib/components/SingleDatePicker.js';
import { Portal } from 'react-portal';
import { renderMonthElement } from './react_dates';
import moment from '../lib/moment';

// Overrides react-dates to render the day picker in our own portal element
// See https://github.com/react-dates/react-dates/blob/master/src/components/SingleDatePicker.jsx#L372-L393
PureSingleDatePicker.prototype.maybeRenderDayPickerWithPortal = function () {
  const { focused, withPortal, withFullScreenPortal, appendToBody } = this.props;

  if (!focused) return null;

  if (withPortal || withFullScreenPortal || appendToBody)
    return <Portal node={document.getElementById('portal')}>{this.renderDayPicker()}</Portal>;

  return this.renderDayPicker();
};

function DatePicker({ initialDate, disabled, blockedRanges, minDate, placeholder, onChange, overlay, theme }) {
  const [isFocused, setIsFocused] = useState(false);
  const [date, setDate] = useState(initialDate && moment(initialDate).isValid() ? moment(initialDate) : null);
  const [id, _] = useState(`date-picker-${Math.random().toString().substring(2)}`);

  const handleDateChange = (date) => {
    setDate(date);
    onChange(date);
  };

  const handleFocusChange = ({ focused }) => {
    setIsFocused(focused);
  };

  const isOutsideRange = (date) => {
    if (minDate && date.isBefore(minDate)) {
      return true;
    }

    return blockedRanges.some(([startDate, endDate]) => moment(date).isBetween(startDate, endDate, 'day', '[]'));
  };

  return (
    <SingleDatePicker
      date={date}
      placeholder={placeholder}
      onDateChange={handleDateChange}
      focused={isFocused}
      onFocusChange={handleFocusChange}
      disabled={disabled}
      id={id}
      displayFormat="L"
      transitionDuration={0}
      noBorder
      block
      hideKeyboardShortcutsPanel
      numberOfMonths={1}
      isOutsideRange={isOutsideRange}
      renderMonthElement={renderMonthElement}
      withPortal={overlay}
      verticalSpacing={4}
      daySize={{ system: 40, website: 44 }[theme]}
      horizontalMonthPadding={{ system: 12, website: 16 }[theme]}
    />
  );
}

export default DatePicker;
