import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];
  static values = { successMessage: String };

  copy() {
    const successMessage = this.successMessageValue;
    const containerClone = this.containerTarget.cloneNode(true);

    // Remove i18n elements
    containerClone.querySelectorAll('#i18n-manager, #i18n-manager-wrapper').forEach((element) => element.remove());

    // Adjust styles
    containerClone.querySelectorAll('*').forEach((element) => {
      // Change margin 0 auto (default in MJML)
      if (element.style.margin === '0px auto') {
        element.style.margin = '0';
      }
      // Fix padding in browser/client
      if (element.width === '40px' && element.paddingLeft === '8px') {
        element.width = '32px';
      }
    });

    // Change align="center" (default in MJML)
    containerClone.querySelectorAll('[align="center"]').forEach((element) => {
      element.setAttribute('align', 'left');
    });

    const markup = containerClone.innerHTML;

    const copyListener = (e) => {
      e.clipboardData.setData('text/html', markup);
      e.clipboardData.setData('text/plain', markup);
      e.preventDefault();
      alert(successMessage);
    };

    document.addEventListener('copy', copyListener);
    document.execCommand('copy');
    document.removeEventListener('copy', copyListener);
  }
}
