import moment from 'moment';
import 'moment/locale/nl.js';
import 'moment/locale/de.js';
import 'moment/locale/fr.js';
import 'moment/locale/cs.js';
import 'moment/locale/da.js';
import 'moment/locale/es.js';
import 'moment/locale/it.js';
import 'moment/locale/nb.js';
import 'moment/locale/pl.js';
import 'moment/locale/pt.js';
import 'moment/locale/tr.js';

if (document.documentElement.lang === 'en') {
  moment.updateLocale('en', { longDateFormat: { L: 'DD-MM-YYYY' } });
} else {
  moment.locale(document.documentElement.lang);
}

export default moment;
