import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends Controller {
  static targets = ['input', 'component'];

  async connect() {
    const DatePicker = await import(`../react_components/date_picker.jsx`);

    const disabled = $(this.inputTarget).prop('disabled');
    const blockedRanges = this.data.has('blockedRanges') ? JSON.parse(this.data.get('blockedRanges')) : [];
    const minDate = this.data.get('minDate');
    const placeholder = $(this.inputTarget).prop('placeholder');
    const overlay = this.data.has('overlay');
    const theme = $(this.element).data('theme');

    ReactDOM.render(
      <DatePicker.default
        initialDate={this.inputTarget.value}
        onChange={this.handleChange}
        disabled={disabled}
        blockedRanges={blockedRanges}
        minDate={minDate}
        placeholder={placeholder}
        overlay={overlay}
        theme={theme}
      />,
      this.componentTarget
    );
  }

  handleChange = (date) => {
    if (date === null) {
      this.inputTarget.value = '';
    } else {
      this.inputTarget.value = date.format('YYYY-MM-DD');
    }

    this.inputTarget.dispatchEvent(new Event('change'));
  };

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.componentTarget);
  }
}
